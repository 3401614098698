<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('createInvoice') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="reservation">
        <h2>{{ $t('invoice') }}</h2>

        <el-row>
          <el-col :span="4">{{ $t('invoiceType') }}</el-col>
          <el-col :span="10">
            <el-select
              v-model="invoice.invoice_type"
            >
              <el-option
                v-for="item of actions"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              >
                <div>
                  {{ item.name }} ({{ item.subname }})
                </div>
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-radio-group v-model="invoice.invoice_category">
              <el-radio label="Normal">{{ $t('VAT invoice Normal') }}</el-radio>
              <el-radio label="Special">{{ $t('VAT invoice Special') }}</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <div class="block" v-if="invoice.invoice_type === 'Platform'">
          <h2>{{ $t('invoiceTitle') }}</h2>
          <van-cell-group class="van-cell-no-padding">
            <van-field
              v-model="invoice.name"
              label-width="120px"
              :label="'*'+$t('invoiceName')"
              :placeholder="$t('pleaseInputInvoiceTitle')"
              maxlength="200"
            />
            <van-field
              v-model="invoice.taxid"
              label-width="120px"
              :label="requiredIcon + $t('invoiceTaxId')"
              :placeholder="$t('pleaseInputTaxId')"
              maxlength="50"
            />
            <van-field
              v-model="invoice.address"
              label-width="120px"
              :label="requiredIcon + $t('invoiceAddress')"
              :placeholder="$t('pleaseInputAddress')"
              maxlength="200"
            />
            <van-field
              v-model="invoice.telephone"
              label-width="120px"
              :label="requiredIcon + $t('invoiceTelephone')"
              :placeholder="$t('pleaseInputTelephone')"
              maxlength="50"
            />
            <van-field
              v-model="invoice.bank"
              label-width="120px"
              :label="requiredIcon + $t('invoiceBank')"
              :placeholder="$t('pleaseInputBank')"
              maxlength="200"
            />

            <van-field
              v-model="invoice.account"
              label-width="120px"
              :label="requiredIcon + $t('invoiceAccount')"
              :placeholder="$t('pleaseInputBankAccount')"
              maxlength="50"
            />
          </van-cell-group>
        </div>

        <div class="block" v-if="invoice.invoice_type === 'Platform'">
          <h2>{{ $t('mailInformation') }}</h2>
          <p>{{ $t('mailNote') }}</p>
          <van-cell-group class="van-cell-no-padding">
            <van-field
              v-model="invoice.mail_name"
              label-width="120px"
              :label="$t('mailName')"
              :placeholder="$t('pleaseInputMailName')"
              maxlength="200"
            />
            <van-field
              v-model="invoice.mail_number"
              label-width="120px"
              :label="$t('mailNumber')"
              :placeholder="$t('pleaseInputMailNumber')"
              maxlength="50"
            />
            <van-field
              type="textarea"
              v-model="invoice.mail_address"
              label-width="120px"
              :label="$t('mailAddress')"
              :placeholder="$t('pleaseInputMailAddress')"
              maxlength="200"
            />
          </van-cell-group>
        </div>

        <div v-if="invoice.invoice_type === 'Hotel'" style="line-height: 1.4rem; padding-top: 10px; color: #5c5c5c;">
          {{ $t('invoiceNote') }}
        </div>
      </div>

      <div style="text-align: center;">
        <el-button
          type="primary"
          size="large"
          @click="save"
          style="width: 300px;"
          :loading="loading"
        >
          {{ $t('save') }}
        </el-button>
      </div>

      <div style="flex: 1;"></div>
      <Footer />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Header from '@/components/Header'
import Invoice from '@/models/invoice'
import { Notify } from 'vant'

export default {
  name: 'Invoice',
  components: {
    Header
  },
  data () {
    return {
      show: false,
      loading: false,
      invoice: new Invoice()
    }
  },
  computed: {
    actions () {
      return [
        { code: 'Platform', name: this.$t('service'), subname: this.$t('invoice provided by platform') },
        { code: 'Hotel', name: this.$t('hotel expense'), subname: this.$t('hotel expense(hotel)') }
      ]
    },
    invoiceType () {
      if (this.invoice.invoice_type) {
        return _.find(this.actions, { code: this.invoice.invoice_type }).name
      }

      return this.$t('pleaseSelectInvoiceType')
    },
    requiredIcon () {
      return this.invoice.invoice_category === 'Special' ? '*' : ''
    }
  },
  async mounted () {
    try {
      const res = await this.$axios.get(`/reservation/${this.$route.params.reservationId}/invoice`)
      Object.assign(this.invoice, res.data)
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    validate () {
      if (!this.invoice.invoice_type) {
        Notify({ type: 'warning', message: this.$t('pleaseSelectInvoiceType') })
        return false
      }

      if (this.invoice.invoice_type === 'Platform') {
        if (!this.invoice.name) {
          Notify({ type: 'warning', message: this.$t('invoiceNameRequired') })
          return false
        }

        if (this.requiredIcon) {
          if (!this.invoice.taxid) {
            Notify({ type: 'warning', message: this.$t('invoiceTaxIdRequired') })
            return false
          }
          if (!this.invoice.address) {
            Notify({ type: 'warning', message: this.$t('invoiceAddressRequired') })
            return false
          }
          if (!this.invoice.telephone) {
            Notify({ type: 'warning', message: this.$t('invoiceTelephoneRequired') })
            return false
          }
          if (!this.invoice.bank) {
            Notify({ type: 'warning', message: this.$t('invoiceBankRequired') })
            return false
          }
          if (!this.invoice.account) {
            Notify({ type: 'warning', message: this.$t('invoiceAccountRequired') })
            return false
          }
        }
      }

      return true
    },
    onSelect (value) {
      this.invoice.invoice_type = value.code
    },
    async save () {
      if (this.validate()) {
        // 提交预订
        try {
          this.loading = true
          await this.$axios.put(`/reservation/${this.$route.params.reservationId}/invoice`, this.invoice)

          this.$router.back()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.reservation {
  padding: 1rem;
}

.date {
  padding: 14px 0;
  .nights {
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
    border-radius: 50%;
    color: #7C7C7C;
  }
}

.guests {
  .van-row {
    padding-top: 10px;
  }
  padding-bottom: 10px;
}

.hotel-name {
  margin-top: .3rem;
  font-size: 1.2rem;
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1rem;
  color: #2766ba;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.policy {
  color: #2766ba;
}

ul {
  list-style: inside;
  li {
    color: #5c5c5c;
    margin-bottom: 10px;
  }
}

.padding-bottom.van-popup {
  bottom: 50px;
}
</style>
